import React from 'react';
import { useTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';

export const ExperienceCard = ({ cargo, empresa, origen, fecha, responsabilidades, url }) => {

    const [t] = useTranslation("global");

    return (
        <div className="col-sm-12 col-md-6">
            <div className="mh-work">
                <div className="mh-experience-deatils bottom_margin">
                    <ScrollAnimation animateIn='fadeInUp'
                        animateOnce={true}
                        delay={400}
                        duration={0.8}
                    >
                        <div className="mh-work-item dark-bg">
                            <h4>{cargo} <a href={ url } target="_blank" rel="noreferrer">{empresa}</a></h4>
                            <div className="mh-eduyear">{fecha}</div>
                            <p> {origen} </p>
                            <span>{t("experience.responsability")} :</span>
                            <ul className="work-responsibility">
                                {
                                    responsabilidades.map(responsabilidad => (
                                        <li key={responsabilidad.id}><i className="fa fa-circle"></i>{responsabilidad.descripcion}</li>
                                    ))
                                }
                            </ul>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    )
}
