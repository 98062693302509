import { db } from "../firebase/firebase-config"

export const loadEducacion = async (ref) => {

    const lref = `${ref}/education`;
    const dbCollection = db.collection(`${lref}`);
    const EducationId = await (await dbCollection.get()).docs[0].id;

    const academias = await loadValoracion(`${lref}/${EducationId}/academias`);
    const cursos = await loadValoracion(`${lref}/${EducationId}/cursos`);
    const cursosAdic = await loadValoracion(`${lref}/${EducationId}/cursosAdic`);

    return {
        academias,
        cursos,
        cursosAdic
    };
}

const loadValoracion = async (ref) => {
    const dbCollection = db.collection(`${ref}`);
    const EducationsSnap = await dbCollection.orderBy('fechaGrado','desc').orderBy('orden','desc').get();
    const studies = [];

    EducationsSnap.forEach(edu => {
        studies.push({
            id: edu.id,
            instituto: edu.data().instituto,
            fechaGrado: edu.data().fechaGrado,
            carrera: edu.data().carrera,
            origen: edu.data().origen,
            url: edu.data().url
        })

    });

    return studies;
}