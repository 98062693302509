import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';

export const AboutScreen = () => {

    const { about } = useSelector(state => state.info);
    const { info, infourl, habilidades } = about;

    const [t] = useTranslation("global");

    // const saltoDeLinea = (texto) => {
    //     //return texto.replace(/\n/g, '<br />');
    //     console.log(texto)
    //     console.log(texto.replace(/&lt;br&gt;/g, '<br/>'))
    //     return texto.replace(/&lt;br&gt;/g, '<br/>');
    // }

    return (
        <>
            <section className="mh-about" id="mh-about">
                <div className="container">
                    <div className="row section-separator">
                        <div className="col-sm-12 col-md-6">
                            <ScrollAnimation animateIn='fadeInUp'
                                animateOnce={true}
                                delay={100}
                                duration={0.8}
                            >
                                <img src="assets/images/about-img.png" alt="aboutme" className="img-fluid borderCircularImg" />
                            </ScrollAnimation>

                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="mh-about-inner">
                                <ScrollAnimation animateIn='fadeInUp'
                                    animateOnce={true}
                                    delay={200}
                                    duration={0.8}
                                >
                                    <h2>{t("about.aboutme")}</h2>
                                </ScrollAnimation>
                                <ScrollAnimation animateIn='fadeInUp'
                                    animateOnce={true}
                                    delay={300}
                                    duration={0.8}
                                >
                                    {
                                        info.map((inf, index) => (
                                            <p key={index}>
                                                {inf}
                                            </p>
                                        ))
                                    }

                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <ScrollAnimation animateIn='fadeInUp'
                                animateOnce={true}
                                delay={200}
                                duration={0.8}
                            >
                                <p style={{ textAlign: 'center ' }}>
                                    {t("about.practice")}:
                            </p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn='fadeInUp'
                                animateOnce={true}
                                delay={300}
                                duration={0.8}
                            >
                                <div className="mh-about-tag mh-about-tag-2">
                                    <ul>
                                        {
                                            habilidades.map(habilidad => (
                                                <li key={habilidad.id}><span>{habilidad.descripcion}</span></li>
                                            ))
                                        }
                                    </ul>
                                    <ScrollAnimation animateIn='fadeInUp'
                                        animateOnce={true}
                                        delay={400}
                                        duration={0.8}
                                    >
                                        {
                                            (infourl) &&
                                            (<a href={infourl} target="_blank" className="btn btn-fill">{t("about.downloadCV")}<i
                                                className="fa fa-download"></i></a>)
                                        }
                                    </ScrollAnimation>

                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
