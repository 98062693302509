import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AboutScreen } from './About/AboutScreen';
import { EducationScreen } from './Education/EducationScreen';
import { HeaderScreen } from './HeaderScreen';
import { ExperienceScreen } from './Experience/ExperienceScreen';
import { FooterScreen } from './Footer/FooterScreen';
import { HomeScreen } from './Home/HomeScreen';
import { SkillsScreen } from './Skills/SkillsScreen';
import { loadCurriculum } from '../actions/infoAction';

export const PrincipalScreen = () => {

   const { idioma } = useSelector(state => state.ui);   

   const dispatch = useDispatch();

   useEffect(() => {

      dispatch(loadCurriculum(idioma));

   }, [idioma,dispatch])

   const { loading } = useSelector(state => state.ui);

    if (loading) {
        return (
            <div className="section-loader" style={{ display: "" }}>
                <div className="loader">
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }

   return (
      <>
         {/* <!-- NAVIGATION  --> */}          
         <HeaderScreen />
         {/* <!-- Home --> */}
         <HomeScreen />

         {/* <!--ABOUT--> */}
         <AboutScreen />

         {/* <!-- EXPERIENCES --> */}
         <ExperienceScreen />



         {/* <!-- SKILLS --> */}
         <SkillsScreen />

         {/* <!-- EDUCATION --> */}
         <EducationScreen />


         {/* <!-- FOOTER 3 --> */}
         <FooterScreen />

      </>
   )
}
