import React from 'react';
import { Provider } from 'react-redux';
import { PrincipalScreen } from './components/PrincipalScreen';
import { store } from './store/store';

// import '../../ styles/header.css';
import './styles/index.css'

export const CVHenryVelasquez = () => {

  return (
    <Provider store={store}>
      <PrincipalScreen />
    </Provider>
  )
}
