import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const HomeScreen = () => {

    const { datosBasicos } = useSelector(state => state.info);

    const { nombre, correo, direccion, perfil, telefono, redes:redesSociales } = datosBasicos;

    const [t] = useTranslation("global");

    return (
        <>
            <section className="mh-home image-bg relative animate__animated animate__fadeIn animate__delay" id="mh-home">
                <div className="img-foverlay img-color-overlay-video">
                    <div className="section-video">
                        <video autoPlay={true} className="bgvid" loop={true} muted={true}>
                            <source src="assets/video/video.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="container">
                        <div className="row section-separator xs-column-reverse vertical-middle-content home-padding">
                            <div className="col-sm-6">
                                <div className="mh-header-info">
                                    {/* <div className="mh-promo wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.1s"> */}
                                    <div className="mh-promo animate__animated animate__fadeInUp animate__delay-1.5s" >
                                        <span>{t("about.helloiam")}</span>
                                    </div>

                                    {/* <h2 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">{nombre}</h2> */}
                                    {/* <h4 className="wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.3s">{perfil}</h4> */}
                                    <h2 className="animate__animated animate__fadeInUp animate__delay-1.7s">{nombre}</h2>
                                    <h4 className="animate__animated animate__fadeInUp animate__delay-1.9s">{perfil}</h4>

                                    <ul>
                                        <li className="animate__animated animate__fadeInUp animate__delay-2.1s"><i className="fa fa-envelope"></i><a
                                            href={`mailto:${correo}`}>{correo}</a></li>
                                        <li className="animate__animated animate__fadeInUp animate__delay-2.3s"><i className="fa fa-phone"></i><a
                                            href={`callto:${telefono}`}>{telefono}</a></li>
                                        <li className="animate__animated animate__fadeInUp animate__delay-2.5s"><i className="fa fa-map-marker"></i>
                                            <address>{direccion}</address>
                                        </li>
                                    </ul>

                                    <ul className="social-icon animate__animated animate__fadeInUp animate__delay-2.1s">
                                        {
                                            redesSociales.map(red => (
                                                <li key={ red.id }>
                                                    <a href={ red.url } target="_blank" rel="noreferrer" alt={ red.red }>
                                                        <i className={ red.icono }></i>
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="hero-img animate__animated animate__fadeInUp animate__delay-2.5s">
                                    <div className="img-border">
                                        <img src="assets/images/perfil.png" alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
