import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import ScrollAnimation from 'react-animate-on-scroll';


export const ContactForm = () => {

    const [t] = useTranslation("global");

    const [mostrar, setmostrar] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_q5xti3b', 'template_h7tmuc7', e.target, 'user_kSDlLf76gpzfP1BQxYhHG')
            .then((result) => {
                e.target.reset();
                setmostrar( true );
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className="col-sm-12 col-md-6">
            <ScrollAnimation animateIn='fadeInUp'
                animateOnce={true}
                delay={200}
                duration={0.8}
            >
                <form id="contactForm" onSubmit={handleSubmit} className="single-form quate-form" data-toggle="validator">
                    {
                        (mostrar) && ( <div id="msgSubmit" className="h3 text-center animate__animated animate__fadeIn animate__delay"> {t("contact.successMsg")} </div>) 
                    }
                    
                    <div className="row">
                        <div className="col-sm-12">
                            <input name="name" className="contact-name form-control" id="name" type="text"
                                placeholder={t("contact.firstname")} required />
                        </div>

                        <div className="col-sm-12">
                            <input name="subject" className="contact-email form-control" id="L_name" type="text"
                                placeholder={t("contact.subject")} required />
                        </div>

                        <div className="col-sm-12">
                            <input name="email" className="contact-subject form-control" id="email" type="email"
                                placeholder={t("contact.youremail")} required />
                        </div>

                        <div className="col-sm-12">
                            <textarea className="contact-message" id="message" rows="6"
                                placeholder={t("contact.message")}
                                name="message"
                                required></textarea>
                        </div>

                        {/* <!-- Subject Button --> */}
                        <div className="btn-form col-sm-12">
                            <button type="submit" className="btn btn-fill btn-block" id="form-submit">{t("contact.sendmessage")}</button>
                        </div>
                    </div>
                </form>
            </ScrollAnimation>
        </div>


    )
}
