import { db } from "../firebase/firebase-config"

export const loadAbout = async (ref) => {

    let lref = `${ref}/about`;
    let dbCollection = db.collection(`${lref}`);
    let aboutSnap = await dbCollection.get();

    const info = {
        aboutId: '',
        aboutInfo: '',
        cvurl: ''
    }

    aboutSnap.forEach(abouthijo => {
        info.aboutId = abouthijo.id;
        info.aboutInfo = abouthijo.data().info;
        info.cvurl =    abouthijo.data().cvurl;
    });

    let habilidades = [];
    if (!!info.aboutId) {
        habilidades = await loadHabilidades(`${lref}/${info.aboutId}`);
    }

    return {
        info: info.aboutInfo,
        infourl: info.cvurl,
        habilidades
    }


}


const loadHabilidades = async (ref) => {
    const lref = `${ref}/habilidades`;
    const dbCollection = db.collection(`${lref}`);
    const HabilidadesSnap = await dbCollection.orderBy('orden','asc').get();
    const habilidades = [];


    HabilidadesSnap.forEach(abouthijo => {
        habilidades.push({
            id: abouthijo.id,
            descripcion: abouthijo.data().habilidad
        });
    });

    return habilidades;
}