import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SkillsBar } from './SkillsBar';
import { SkillsCircle } from './SkillsCircle';

export const SkillsScreen = () => {

    const { skills } = useSelector(state => state.info);

    const { tecnicas, profesional, idiomas } = skills;

    const [t] = useTranslation("global");

    return (
        <>
            <section className="mh-skills" id="mh-skills">
                <div className="home-v-img">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="col-sm-12 col-md-6">

                                <SkillsBar
                                    titulo={t("skills.langSkills")}
                                    skills={idiomas}
                                />

                                <SkillsBar
                                    titulo={t("skills.techSkills")}
                                    skills={tecnicas}
                                />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <SkillsCircle
                                    titulo = { t("skills.profSkills") }
                                    skills = { profesional }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
