import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { CircleProgress } from './CircleProgress';

export const SkillsCircle = ({titulo, skills}) => {
    return (
        <>
            <ScrollAnimation animateIn='fadeInUp'
                animateOnce={true}
                delay={500}
                duration={0.8}
            >
                <div className="mh-professional-skills">
                    <h3>{titulo}</h3>
                    <ul className="mh-professional-progress">
                        {
                            skills.map(skill => (
                                <CircleProgress
                                    key={skill.id}
                                    {...skill}
                                />
                            ))
                        }
                    </ul>
                </div>
            </ScrollAnimation>
        </>
    )
}
