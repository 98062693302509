import { db } from "../firebase/firebase-config"

export const loadSkills = async (ref) => {

    const lref = `${ref}/skills`;
    const dbCollection = db.collection(`${lref}`);
    const SkillsId = await (await dbCollection.get()).docs[0].id;

    const idiomas = await loadValoracion(`${lref}/${SkillsId}/idiomas`);
    const profesional = await loadValoracion(`${lref}/${SkillsId}/profesional`);
    const tecnicas = await loadValoracion(`${lref}/${SkillsId}/tecnicas`);
    
    return {
        idiomas,
        profesional,
        tecnicas        
    };
}

const loadValoracion = async (ref) => {
    const dbCollection = db.collection(`${ref}`);
    const SkillsSnap = await dbCollection.orderBy('valoracion','desc').get();
    const skills = [];

    SkillsSnap.forEach(skill => {
        skills.push({
            id: skill.id,
            descripcion: skill.data().descripcion,
            valoracion: skill.data().valoracion
        })

    });

    return skills;
}