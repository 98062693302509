import { typesActions } from "../types/types";
import { db } from '../firebase/firebase-config'
import { loadAbout } from "../helpers/loadAbout";
import { finishLoading, startLoading } from "./uiAction";
import { loadDatosPersonales } from "../helpers/loadDatosPersonales";
import { loadWorkExperience } from "../helpers/loadWorkExperience";
import { loadSkills } from "../helpers/loadSkills";
import { loadEducacion } from "../helpers/loadEducacion";

export const loadCurriculum = ( idioma ) => {
    return async ( dispatch ) => {
        dispatch( startLoading() );
        const lref = `/curriculum/sKkG3y7g2dKVYDowsqGM/${idioma}`;
        const curriculumRefLang = db.collection(`${lref}`);
        const curriculumLang = await curriculumRefLang.get();

        let curLangId;
        curriculumLang.forEach( curriculum => {
            curLangId = curriculum.id;
        });

        const urlbase = lref+'/'+curLangId;

        const datosBasicos = await loadDatosPersonales(urlbase);
        dispatch(setDatosBasicosInfo(datosBasicos));

        const about = await loadAbout(urlbase);
        dispatch(setAboutInfo(about));
        
        const work = await loadWorkExperience(urlbase);
        dispatch(setWorkInfo(work));

        const skills = await loadSkills( urlbase );
        dispatch( setSkillsInfo( skills ));

        const studies = await loadEducacion( urlbase );
        dispatch( setStudiesInfo( studies ));

        dispatch( finishLoading() );
    }
};

const setAboutInfo = (about) =>(
    {
        type: typesActions.infoAddAbout,
        payload: about
    }
);

const setDatosBasicosInfo = ( info ) =>(
    {
        type: typesActions.infoAddBasic,
        payload: info
    }
);

const setWorkInfo = ( work ) => (
    {
        type: typesActions.infoAddWork,
        payload: work
    }
)

const setSkillsInfo = ( skills ) => (
    {
        type: typesActions.infoAddSkills,
        payload: skills
    }
)
    
const setStudiesInfo = ( studies ) => (
    {
        type: typesActions.infoAddStudies,
        payload: studies
    }
)
