import { typesActions } from '../types/types';

const initialState = {
    datosBasicos: {
        nombre: '',
        perfil: '',
        correo: '',
        telefono: '',
        direccion: '',
        redes : []
    },
    about: {
        info: [],
        infourl: '',
        habilidades: [
            {
                id: '',
                descripcion: ''
            }
        ]
    },
    workExp: [
        {
            id: '',
            cargo: '',
            empresa: '',
            fecha: '',
            responsabilidades: [
                {
                    id: '',
                    descripcion: ''
                }
            ],
            origen: '',
            url: ''
        }
    ],
    skills: {
        tecnicas: [
            {
                descripcion: '',
                valoracion: ''
            }
        ],
        profesional: [
            {
                descripcion: '',
                valoracion: ''
            }
        ],
        idiomas: [
            {
                descripcion: '',
                valoracion: ''
            }
        ]
    },
    education: {
        academias: [
            {
                instituto: '',
                fechaGrado: '',
                carrera: '',
                origen: '',
                url: ''
            }
        ],
        cursos: [
            {
                instituto: '',
                fechaGrado: '',
                carrera: '',
                origen: '',
                url: ''
            }
        ],
        cursosAdic: [
            {
                instituto: '',
                fechaGrado: '',
                carrera: '',
                origen: '',
                url: ''
            }
        ]
    }
}

export const infoReducer = (state = initialState, action) => {

    switch (action.type) {
        case typesActions.infoAddAbout:
            return {
                ...state,
                about: action.payload
            }

        case typesActions.infoAddBasic:
            return {
                ...state,
                datosBasicos: action.payload
            }

        case typesActions.infoAddWork:
            return {
                ...state,
                workExp: action.payload
            }

        case typesActions.infoAddSkills:
            return {
                ...state,
                skills: action.payload
            }

        case typesActions.infoAddStudies:
            return {
                ...state,
                education: action.payload
            }

        default:
            return state;
    }
}