import { db } from "../firebase/firebase-config"

export const loadDatosPersonales = async (ref) => {

    const lref = `${ref}/datosBasicos`;
    const dbCollection = db.collection(`${lref}`);
    const datosBasicos = await dbCollection.get();

    const datosBasicosId = await datosBasicos.docs[0].id;

    const redes = await loadRedes(`${lref}/${datosBasicosId}/redessociales`);

    const datos = {
        nombre: '',
        perfil: '',
        correo: '',
        telefono: '',
        direccion: '',
        redes: []
    };

    datosBasicos.forEach(dato => {
        datos.nombre = dato.data().nombre;
        datos.perfil = dato.data().perfil;
        datos.correo = dato.data().correo;
        datos.telefono = dato.data().telefono;
        datos.direccion = dato.data().direccion;
        datos.redes = redes;
    });

    return datos;

}


const loadRedes = async (ref) => {
    const dbCollection = db.collection(`${ref}`);
    const RedesSnap = await dbCollection.where('orden','>',0).orderBy('orden','asc').get();
    const redes = [];

    RedesSnap.forEach(red => {
        redes.push({
            id: red.id,
            icono: red.data().icono,
            red: red.data().red,
            url: red.data().url
        })

    });

    return redes;
}