import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ExperienceCard } from './ExperienceCard';

export const ExperienceScreen = () => {

    const { workExp } = useSelector(state => state.info);

    const [t] = useTranslation("global");

    return (
        <>
            <section className="mh-experince image-bg featured-img-one" id="mh-experience">
                <div className="img-color-overlay">
                    <div className="container">
                        <div className="row section-separator">
                            <div className="col-sm-12">
                                <div className="mh-work">
                                    <ScrollAnimation animateIn='fadeInUp'
                                        animateOnce={true}
                                        delay={200}
                                        duration={0.8}
                                        offset = { 10 }
                                    >
                                        <h3>{t("experience.workExperience")}</h3>
                                    </ScrollAnimation>
                                </div>
                            </div>

                            {
                                workExp.map(work => (
                                    <ExperienceCard
                                        key={work.id}
                                        {...work}
                                    />
                                ))
                            }


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
