import { db } from "../firebase/firebase-config"

export const loadWorkExperience = async (ref) => {

    const lref = `${ref}/workExp`;
    const dbCollection = db.collection(`${lref}`);
    const WorksSnap = await (await dbCollection.orderBy('orden','desc').get()).docs;

    const works = [];

    for (let index = 0; index < WorksSnap.length; index++) {
        const responsabilidades = await loadWorkResponsabilidades(`${lref}/${WorksSnap[index].id}`)
        works.push({
            id: WorksSnap[index].id,
            cargo: WorksSnap[index].data().cargo,
            empresa: WorksSnap[index].data().empresa,
            fecha: WorksSnap[index].data().fecha,
            origen: WorksSnap[index].data().origen,
            url: WorksSnap[index].data().url,
            responsabilidades: responsabilidades
        })
    }

    return works;
}

const loadWorkResponsabilidades = async (ref) => {
    const lref = `${ref}/responsabilidades`;
    const dbCollection = db.collection(`${lref}`);
    const ResponsSnap = await dbCollection.orderBy('orden','asc').get();
    const responsabilidades = [];

    ResponsSnap.forEach(responhijo => {
        responsabilidades.push({
            id: responhijo.id,
            descripcion: responhijo.data().responsabilidad
        })

    });

    return responsabilidades;
}