import React from 'react';

export const ProgressBar = ({ descripcion, valoracion }) => {
    return (
        <div className="candidatos">
            <div className="parcial">
                <div className="info">
                    <div className="nome">{descripcion}</div>
                    <div className="percentagem-num">{`${valoracion}%`}</div>
                </div>
                <div className="progressBar">
                    <div className="percentagem" style={{ width: `${valoracion}%` }}></div>
                </div>
            </div>
        </div>
    )
}
