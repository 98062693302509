

export const typesActions = {
    web_language: '[UI] Set idioma de web',
    uiStartLoading: '[UI] Show Loading',
    uiFinishLoading: '[UI] Finish Loading',


    infoAddBasic: '[INFO] Set datos basicos',
    infoAddAbout: '[INFO] Set datos acerca de me',
    infoAddWork: '[INFO] Set datos trabajo',
    infoAddSkills: '[INFO] Set datos habilidades',
    infoAddStudies: '[INFO] Set datos estudios'
}