import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { ProgressBar } from './ProgressBar';

export const SkillsBar = ( { titulo, skills }) => {
    return (
        <div className="mh-skills-inner">
            <ScrollAnimation animateIn='fadeInUp'
                animateOnce={true}
                delay={300}
                duration={0.8}
            >
                <div className="mh-professional-skill">
                    <h3>{ titulo }</h3>
                    <div className="each-skills">
                        {
                            skills.map(skill => (
                                <ProgressBar
                                    key={skill.id}
                                    {...skill}
                                />
                            ))
                        }
                    </div>
                </div>
            </ScrollAnimation>

        </div>
    )
}
