import { typesActions } from "../types/types";

const initialState = {
    idioma: 'es',
    loading: true
}

export const uiReducer = (state = initialState, action) => {

    switch (action.type) {
        case typesActions.web_language:
            return {
                ...state,
                idioma: action.payload
            }
        case typesActions.uiStartLoading:
            return {
                ...state,
                loading: true
            }

        case typesActions.uiFinishLoading:
            return {
                ...state,
                loading: false
            }

        default:
            return state;
    }
}