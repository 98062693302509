import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ContactForm } from './ContactForm'

export const FooterScreen = () => {

    const { datosBasicos } = useSelector(state => state.info);

    const { correo, direccion, telefono, redes: redesSociales } = datosBasicos;

    const [t] = useTranslation("global");

    return (
        <>
            <footer className="mh-footer mh-footer-3" id="mh-contact">
                <div className="container-fluid">
                    <div className="row section-separator">
                        <div className="col-sm-12 section-title">
                            <ScrollAnimation animateIn='fadeInUp'
                                animateOnce={true}
                                delay={200}
                                duration={0.8}
                                offset={10}
                            >
                                <h3>{t("contact.contactme")}</h3>
                            </ScrollAnimation>
                        </div>
                        <div className="map-image image-bg col-sm-12">
                            <div className="container mt-30">
                                <div className="row">
                                    <div className="col-sm-12 col-md-6 mh-footer-address">
                                        <div className="col-sm-12 xs-no-padding">
                                            <ScrollAnimation animateIn='fadeInUp'
                                                animateOnce={true}
                                                delay={300}
                                                duration={0.8}
                                            >
                                                <div className="mh-address-footer-item dark-bg shadow-1 media">
                                                    <div className="each-icon">
                                                        <i className="fa fa-location-arrow"></i>
                                                    </div>
                                                    <div className="each-info media-body">
                                                        <h4>{t("contact.address")}</h4>
                                                        <address>
                                                            {direccion}
                                                        </address>
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                        <div className="col-sm-12 xs-no-padding">
                                            <ScrollAnimation animateIn='fadeInUp'
                                                animateOnce={true}
                                                delay={400}
                                                duration={0.8}
                                            >
                                                <div className="mh-address-footer-item media dark-bg shadow-1">
                                                    <div className="each-icon">
                                                        <i className="fa fa-envelope-o"></i>
                                                    </div>
                                                    <div className="each-info media-body">
                                                        <h4>{t("contact.email")}</h4>
                                                        <a href={`mailto:${correo}`}>{correo}</a>
                                                    </div>
                                                </div>
                                            </ScrollAnimation>
                                        </div>
                                        <div className="col-sm-12 xs-no-padding">
                                            <ScrollAnimation animateIn='fadeInUp'
                                                animateOnce={true}
                                                delay={500}
                                                duration={0.8}
                                            >
                                                <div className="mh-address-footer-item media dark-bg shadow-1">
                                                    <div className="each-icon">
                                                        <i className="fa fa-phone"></i>
                                                    </div>
                                                    <div className="each-info media-body">
                                                        <h4>{t("contact.phone")}</h4>
                                                        <a href={`callto:${telefono}`}>{telefono}</a>
                                                    </div>
                                                </div>
                                            </ScrollAnimation>

                                        </div>
                                    </div>
                                    <ContactForm />

                                    <div className="col-sm-12 mh-copyright wow fadeInUp" data-wow-duration="0.8s" data-wow-delay="0.2s">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="text-left text-xs-center">
                                                    <p>All right reserved Henry Velasquez @ 2021</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <ul className="social-icon">
                                                    {
                                                        redesSociales.map(red => (
                                                            <li key={red.id}>
                                                                <a href={red.url} target="_blank" rel="noreferrer" alt={red.red}>
                                                                    <i className={red.icono}></i>
                                                                </a>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
