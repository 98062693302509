import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDWjAnkBw3_vtZe0HLPXlc4nfSqAkiy5fc",
    authDomain: "hv-sql-demos.firebaseapp.com",
    projectId: "hv-sql-demos",
    storageBucket: "hv-sql-demos.appspot.com",
    messagingSenderId: "168341540554",
    appId: "1:168341540554:web:25c4f9a90faf4fbdb2ae33"
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);


  const db = firebase.firestore();


  export {
      db,
      firebase
  }