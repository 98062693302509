import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export const CircleProgress = ({ descripcion, valoracion }) => {

    return (
        <li>
            <CircularProgressbar
                className="mh-progress mh-progress-circle"
                value={Math.round(valoracion)}
                text={`${Math.round(valoracion)}%`}
            />
            <div className="pr-skill-name">{descripcion}</div>
        </li>
    )
}
