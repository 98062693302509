import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { EducationCard } from './EducationCard';

export const Education = ({ titulo, estudios }) => {
    return (
        <>
            <div className="col-sm-12">
                <div className="mh-education">
                    <ScrollAnimation animateIn='fadeInUp'
                        animateOnce={true}
                        delay={200}
                        duration={0.8}
                        offset = { 10 }
                    >
                        <h3>{titulo}</h3>

                    </ScrollAnimation>
                </div>
            </div>

            {
                estudios.map(estudio => (
                    <EducationCard
                        key={estudio.id}
                        {...estudio}
                    />
                ))
            }
        </>
    )
}
