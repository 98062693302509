import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Education } from './Education';


export const EducationScreen = () => {

    const [t] = useTranslation("global");

    const { education } = useSelector(state => state.info);

    const { academias, cursos, cursosAdic } = education;

    return (
        <>
            <section className="mh-experince image-bg featured-img-one" id="mh-education">
                <div className="img-color-overlay">
                    <div className="container">
                        <div className="row section-separator">

                            <Education
                                titulo={t("studies.education")}
                                estudios={academias}
                            />

                            <Education
                                titulo={t("studies.courses")}
                                estudios={cursos}
                            />

                            <Education
                                titulo={t("studies.coursesadic")}
                                estudios={cursosAdic}
                            />


                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
