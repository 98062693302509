import 'core-js/stable';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { CVHenryVelasquez } from './CVHenryVelasquez';

import global_es from './translations/es/global_es.json';
import global_en from './translations/en/global_en.json';

i18next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    },
  },
})

ReactDOM.render(
  <I18nextProvider i18n = { i18next }>
    <CVHenryVelasquez />
  </I18nextProvider>,
  document.getElementById('root')
);

