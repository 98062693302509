import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-scroll'
import { setLanguage } from '../actions/uiAction';

export const HeaderScreen = () => {

    const [t, i18n] = useTranslation("global");

    const dispatch = useDispatch();

    const handleLanguajeChange = (idioma) => {
        i18n.changeLanguage(idioma);
        dispatch(setLanguage(idioma));
        handleToggle();
    }

    const [mostrar, setMostrar] = useState(false);
    const [scrollNav, setscrollNav] = useState(false)

    const handleToggle = () => {
        setMostrar(!mostrar);
    }

    const changeScrollNav = () => {
        if ( window.scrollY >= 80){
            setscrollNav(true);
        }else{
            setscrollNav(false);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", changeScrollNav);
    }, [])

    
    



    return (
        <>
            <header className={"black-bg mh-header mh-fixed-nav nav-scroll mh-xs-mobile-nav " + (scrollNav ? " nav-strict" : "")} id="mh-header">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <nav className="navbar navbar-expand-lg mh-nav nav-btn">
                            <a className="navbar-brand" href="/">
                            </a>
                            <button onClick={handleToggle} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon icon"></span>
                            </button>

                            <div className={"collapse navbar-collapse " + (mostrar ? "active" : "")} id="navbarSupportedContent">

                                <button className="btn_idiomas" onClick={() => handleLanguajeChange('en')} >
                                    <img src="assets/images/idiomas/eng.png" alt="" className="img-fluid" />
                                </button>
                                <button className="btn_idiomas" onClick={() => handleLanguajeChange('es')}>
                                    <img src="assets/images/idiomas/esp.png" alt="" className="img-fluid" />
                                </button>
                                <ul className="navbar-nav mr-0 ml-auto">
                                    <li className="nav-item">
                                        <Link onClick={handleToggle} activeClass="active" className="nav-link" to="mh-home" spy={true} smooth={true} offset={0} duration={500} >
                                            {t("menu.home")}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link onClick={handleToggle} activeClass="active" className="nav-link" to="mh-about" spy={true} smooth={true} offset={0} duration={500} >
                                            {t("menu.about")}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link onClick={handleToggle} activeClass="active" className="nav-link" to="mh-experience" spy={true} smooth={true} offset={0} duration={500} >
                                            {t("menu.experience")}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link onClick={handleToggle} activeClass="active" className="nav-link" to="mh-skills" spy={true} smooth={true} offset={-70} duration={500} >
                                            {t("menu.skills")}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link onClick={handleToggle} activeClass="active" className="nav-link" to="mh-education" spy={true} smooth={true} offset={0} duration={500} >
                                            {t("menu.studies")}
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link onClick={handleToggle} activeClass="active" className="nav-link" to="mh-contact" spy={true} smooth={true} offset={0} duration={500} >
                                            {t("menu.contact")}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )
}
