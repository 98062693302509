import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export const EducationCard = ({ carrera, instituto, fechaGrado, origen, url }) => {
    return (
        <div className="col-sm-12 col-md-6">
            <div className="mh-education">
                <div className="mh-education-deatils bottom_margin">
                    <ScrollAnimation animateIn='fadeInUp'
                        animateOnce={true}
                        delay={300}
                        duration={0.8}
                    >
                        <div className="mh-education-item dark-bg">
                            <h4>{carrera} <a href={ url } target="_blank" rel="noreferrer">{instituto}</a></h4>
                            <div className="mh-eduyear">{fechaGrado}</div>
                            <p>{origen}</p>
                        </div>

                    </ScrollAnimation>

                </div>
            </div>
        </div>
    )
}
