import { typesActions } from "../types/types";

export const setLanguage = (language) => {
    return {
        type: typesActions.web_language,
        payload: language
    }
}


export const startLoading = () => (
    {
        type: typesActions.uiStartLoading
    }
)

export const finishLoading = () => (
    {
        type: typesActions.uiFinishLoading
    }
)